<template>
  <div v-if="isshowgrid">
    <va-card title="Drm Channel">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input class="va-input-search-container"
            :value="term"
            placeholder="search"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>
      </div>
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        clickable
      >
        <template slot="actions" slot-scope="props">
          <va-button flat small color="gray" icon="fa fa-pencil" @click="edit(props.rowData)" class="ma-0"></va-button>
        </template>
      </va-data-table>
    </va-card>
  </div>
  <div v-else-if='isshowForm'>
    <div>
      <va-card :title="title">
        <template slot="actions">
          <va-button color="gray" @click="list()">List</va-button>
        </template>
        <div>
          <form>
            <span class="va-form-label va-form-required-label">Channel Name</span>
            <va-input
              v-model="channel_name"
              disabled
            />
            <span class="va-form-label va-form-required-label">Channel logo</span>
            <div class="ml-2 mt-2">
              <div><img :src=logo_url height="100px" width="100px" /></div>
            </div>
            <span class="va-form-label va-form-required-label">Channel Cost</span>
            <va-input
              v-model="channel_cost"
              disabled
            />
            <span class="va-form-label va-form-required-label">Channel Type</span>
            <va-input
              v-model="channel_type"
              disabled
            />
            <span class="va-form-label va-form-required-label">Quality</span>
            <va-input
              v-model="quality"
              disabled
            />
            <span class="va-form-label va-form-required-label">Language</span>
            <va-input
              v-model="language"
              disabled
            />
            <span class="va-form-label va-form-required-label">Genres</span>
            <va-input
              v-model="genres"
              disabled
            />
            <span class="va-form-label va-form-required-label">LCN Number</span>
            <va-input
              v-model="lcn_number"
              disabled
            />
            <span class="va-form-label va-form-required-label">Epg Channel Name</span>
            <va-input
              v-model="epg_channel_name"
              type ='text'
              placeholder = 'Enter epg channel name'
              :error="!!EpgChannelNameErrors.length"
              :error-messages="EpgChannelNameErrors"
            />
            <div class="d-flex justify--end mt-3">
              <va-button
                @click.prevent="updateepg()"
                type="submit"
              >
                Update
              </va-button>
              <va-button
                @click="list()"
                type="submit"
              >
                Cancel
              </va-button>
            </div>
          </form>
        </div>
      </va-card>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import vueResource from 'vue-resource'
import { search } from '../../i18n/search.js'
import config from '../../i18n/en.json'
import { debounce } from 'lodash'
Vue.use(vueResource)
export default {
  name: 'playstoreaccount',
  created () {
    this.getAllDrmChannels()
  },
  data () {
    return {
      term: null,
      perPage: '10',
      perPageOptions: ['4', '6', '10', '20'],
      isshowgrid: false,
      isshowForm: false,
      logo_url: '',
      channel_name: '',
      channel_cost: '',
      channel_type: '',
      quality: '',
      genres: '',
      lcn_number: '',
      language: '',
      epg_channel_name: '',
      EpgChannelNameErrors: [],
      EpgChannelNameErrorsMessage: [],
      drmChannelData: [],

    }
  },
  computed: {
    formReady () {
      return !this.EpgChannelNameErrors.length
    },
    fields () {
      return [{
        name: 'sno',
        title: 'S.NO',
      },
      {
        name: 'channel_name',
        title: 'Channel Name',

      },
      {
        name: 'channel_logo',
        title: 'Channel Logo',
      },
      {
        name: 'channel_cost',
        title: 'Channel Cost',
      },
      {
        name: 'channel_type',
        title: 'Channel Type',
      },
      {
        name: 'quality',
        title: 'Quality',
      },
      {
        name: 'language',
        title: 'Language',
      },
      {
        name: 'genres',
        title: 'Genres',
      },
      {
        name: 'lcn_number',
        title: 'LCN Number',
      },
      {
        name: 'epg_channel_name',
        title: 'Epg Channel Name',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      return search(this.term, this.drmChannelData)
    },
  },
  methods: {
    getAllDrmChannels () {
      this.$http.get(config.menu.host + '/drm_channel').then(response => {
        let index = 0
        this.drmChannelData = response.body.map(function (item) {
          item.id = index++
          item.sno = index
          return item
        })
        this.isshowForm = false
        this.isshowgrid = true
      })
    },

    updateepg () {
      this.EpgChannelNameErrors = this.epg_channel_name ? [] : ['Epg channel Name is required']
      if (!this.formReady) { return };
      var payload = {
        epg_channel_name: this.epg_channel_name,
      }

      const loader = Vue.$loading.show({ width: 40, height: 40 })
      this.$http.put(config.menu.host + '/drm_channel/' + this.channel_id, payload).then(responseData => {
        loader.hide()
        if (responseData && responseData.body) {
          Vue.notify({ text: responseData.body, type: 'success' })
          this.list()
        }
      }, errResponse => {
        loader.hide()
        if (errResponse && errResponse.body) {
          Vue.notify({ text: errResponse.body, type: 'error' })
          this.list()
        }
      })
    },
    edit (row) {
      this.title = 'Update channel'
      this.isshowgrid = false
      this.isshowForm = true
      this.channel_id = row.channel_id
      this.logo_url = 'https://assets.skie.tv/' + row.channel_logo
      this.channel_name = row.channel_name
      this.channel_cost = row.channel_cost
      this.quality = row.quality
      this.channel_type = row.channel_type
      this.language = row.language
      this.genres = row.genres
      this.lcn_number = row.lcn_number
      this.epg_channel_name = row.epg_channel_name
    },
    list () {
      this.title = ''
      this.logo_url = ''
      this.channel_name = ''
      this.channel_cost = ''
      this.quality = ''
      this.channel_type = ''
      this.language = ''
      this.genres = ''
      this.lcn_number = ''
      this.epg_channel_name = ''
      this.isshowForm = false
      this.isshowgrid = true
      this.drmChannelData = []
      this.getAllDrmChannels()
    },
    search: debounce(function (term) {
      this.term = term
    }, 400),
  },
}
</script>
